<template>
  <div class="form-group" :class="{ 'required': required, 'has-error': errors.length }">
    <label :for="name">{{ titleValue }}</label>
    <div class="additional-info" v-if="$slots['info']">
      <slot name="info"/>
    </div>
    <div class="form-check">
      <div class="button">
        <form-check v-bind="$props"/>
        <div class="knobs">
          <span></span>
        </div>
        <div class="layer"></div>
      </div>
    </div>
    <p class="help-block info" v-if="$slots['help']">
      <slot name="help"/>
    </p>
    <p class="help-block error" v-for="(error, i) in errors" :key="`${name}-error-${i}`">{{ error }}</p>
  </div>
</template>

<script>
import FormCheck from '@/views/components/form/FormCheck'

export default {
  name: 'FormGroupCheck',
  components: {
    FormCheck
  },
  props: {
    title: { type: String, default: '' },
    ...FormCheck.props
  },
  computed: {
    titleValue () { return this.title ? this.title : this.$helpers.Str.titleCase(this.name) },
    errors () { return this.model.errors }
  }
}
</script>

<style lang="scss">
.form-check {
  .button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 34px;
    overflow: hidden;

    input[type=checkbox] {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      opacity: 0;
      appearance: none;
      cursor: pointer;
      z-index: 3;
      &:checked {
        & + .knobs span {
          left: 55%;
          background-color: #3c8dbc;
        }
      }
      &:disabled {
        cursor: not-allowed;
        &  + .knobs span {
          background-color: #d2d6de;
        }
        & ~ .layer {
          background-color: #eeeeee;
        }
      }
      &:focus {
        &  + .knobs span {
          //background-color: #d2d6de;
        }
        & ~ .layer {
          border-color: #3c8dbc;
        }
      }
    }
    .layer, .knobs {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
    }
    .layer {
      width: 100%;
      border: 1px solid #d2d6de;
      transition: 0.3s ease all;
      z-index: 1;
    }
    .knobs {
      z-index: 2;

      &:before, &:after, & span {
        position: absolute;
        top: 15%;
        width: 35%;
        height: 70%;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        padding: 0;
        border-radius: 2px;
        transition: 0.3s ease all;
      }
      &:before, &:after {
        color: #4e4e4e;
        padding: 11% 0;
        z-index: 1;
      }
      &:before {
        content: 'YES';
        left: 10%;
      }
      &:after {
        content: 'NO';
        right: 10%;
      }
      & span {
        left: 10%;
        background-color: #dd4b39;
        z-index: 2;
      }
    }
  }
}
</style>
