<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <input type="checkbox" v-bind="attributes" v-model="model.value">
</template>

<script>
export default {
  name: 'FormCheck',
  props: {
    model: { type: Object, default: undefined },
    name: { type: String, required: true },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  computed: {
    attributes () {
      return {
        id: this.name,
        name: this.name,
        disabled: this.disabled,
        required: !this.disabled && this.required
      }
    }
  }
}
</script>
